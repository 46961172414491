import { createRouter, createWebHashHistory } from "vue-router";

const Login = () => import("@/view/Login.vue");
const Register = () => import("@/view/Register.vue");
const FirmLogin = () => import("@/view/firmLogin.vue");
const Index = () => import("@/view/home/index.vue");
const Smart = () => import("@/view/smart/index.vue");
const BooksInfo = () => import("@/view/booksInfo/index.vue");
const StoryInfo = () => import("@/view/storyInfo/index.vue");
const Individual = () => import("@/view/individual/index.vue");
const Points = () => import("@/view/points/index.vue");
const HelpDocument = () => import("@/view/helpDocument/index.vue");
const Writing = () => import("@/view/writing/index.vue");
const FirmManage = () => import("@/view/firmManage/index.vue");
const Examine = () => import("@/view/examine/index.vue");

const routes = [
  {
    path: "/",
    redirect: "/writing",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/firmLogin",
    name: "firmLogin",
    component: FirmLogin,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/writing",
    name: "writing",
    component: Writing,
  },
  {
    path: "/smart",
    name: "smart",
    component: Smart,
  },
  {
    path: "/booksInfo",
    name: "booksInfo",
    component: BooksInfo,
  },
  {
    path: "/storyInfo",
    name: "storyInfo",
    component: StoryInfo,
  },
  {
    path: "/individual",
    name: "individual",
    component: Individual,
  },
  {
    path: "/points",
    name: "points",
    component: Points,
  },
  {
    path: "/helpDocument",
    name: "helpDocument",
    component: HelpDocument,
  },
  {
    path: "/examine",
    name: "examine",
    component: Examine,
  },
  {
    path: "/firmManage",
    name: "firmManage",
    component: FirmManage,
    redirect: "/team/manage",
    children: [
      {
        path: "/team/manage",
        name: "manage",
        component: () => import("@/view/team/manage"),
      },
      {
        path: "/team/pointsDetail",
        name: "pointsDetail",
        component: () => import("@/view/team/pointsDetail"),
      },
    ],
  },
  {
    path: "/index",
    name: "index",
    component: Index,
    redirect: "/create",
    children: [
      {
        path: "/create",
        name: "create",
        component: () => import("@/view/create"),
      },
      {
        path: "/books",
        name: "books",
        component: () => import("@/view/books"),
      },
      {
        path: "/story",
        name: "story",
        component: () => import("@/view/story"),
      },
      {
        path: "/forum",
        name: "forum",
        component: () => import("@/view/forum"),
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "闲云"; //设置默认的标题
  let userInfo = JSON.parse(sessionStorage.getItem("userInfo")) || "";
  let token = userInfo.token;
  if (token) {
    if (
      to.name == "login" ||
      to.name == "index" ||
      to.name == "register" ||
      to.name == "writing" ||
      to.name == "firmLogin" ||
      to.name == "examine"
    ) {
      next({ path: "/index" });
      // router.push(from.path)
    } else {
      next();
    }
  } else {
    //没有token就回到登录页
    if (
      to.name == "login" ||
      to.name == "register" ||
      to.name == "writing" ||
      to.name == "firmLogin" ||
      to.name == "examine"
    ) {
      next();
    } else {
      next({ path: "/firmLogin" });
    }
  }
});
export default router;
